import React, { useCallback, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { globalState } from 'shared/foreground/models';
import { addFeed } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/feed';
import { createToast } from 'shared/foreground/toasts.platform';
import copyTextToClipboard from 'shared/foreground/utils/copyTextToClipboard';
import { DisplayTheme } from 'shared/types';
import { isValidRssUrl } from 'shared/utils/isValidRssUrl';

import Button from '../Button';
import { FileUploadContext } from '../FileDropzone';
import NewsletterIconLightMode from '../icons/24SolidNewsLetterIcon';
import NewsletterIconDarkMode from '../icons/24SolidNewsLetterIconDarkMode';
import FeedIcon from '../icons/24StrokeFeedIcon';
import StrokeUploadIcon from '../icons/24StrokeUploadIcon';
import SolidTwitterIcon from '../icons/SolidTwitterIcon';
import { AccountSettingsPage, List, ListItem } from './AccountSettingsPage';
import styles from './AddToFeedPage.module.css';

export function AddToFeedPage() {
  return (
    <AccountSettingsPage
      subtitle="Feed is where documents that are automatically pushed to you live"
      title="Add to Feed"
    >
      <AddToFeed />
    </AccountSettingsPage>
  );
}

const AddToFeed = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState('');
  const { openFileDialog } = useContext(FileUploadContext);
  const profile = globalState(useCallback((state) => state.client.profile, []));
  const feedEmail = profile?.custom_feed_email;

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!url) {
      return;
    }

    if (!isValidRssUrl(url)) {
      createToast({
        content: `Please, use a valid URL`,
        category: 'error',
      });
      return;
    }

    setIsLoading(true);
    addFeed({ url }, { userInteraction: 'unknown' });
    history.push({
      pathname: '/feed',
      search: '?shouldSelectLatestFeedDoc=true',
    });
  };

  const buttonValue = isLoading ? 'Loading...' : 'Subscribe';

  const copyEmail = useCallback(() => {
    if (feedEmail) {
      copyTextToClipboard(feedEmail);
    }
  }, [feedEmail]);

  return (
    <List className={styles.wrapper}>
      <ListItem
        image={<FeedIcon className={styles.feedIcon} />}
        title="Add RSS subscription"
        subtitle={
          <span>
            View a list of <Link to="/feed/sources">RSS feeds</Link> or{' '}
            <Link to="/feed/suggestions">suggested feeds</Link>.
          </span>
        }
        isSmall
      >
        <div className={styles.inputWithButton}>
          <form onSubmit={onSubmit}>
            <input
              required
              aria-labelledby="add from url"
              onChange={(e) => setUrl(e.target.value)}
              type="url"
              autoComplete="off"
              placeholder="http://"
            />
            <Button variant="secondary" type="submit">
              {buttonValue}
            </Button>
          </form>
        </div>
      </ListItem>
      <ListItem
        image={<StrokeUploadIcon className={styles.primaryIcon} />}
        title="Upload OPML file"
        subtitle=".opml and .xml supported."
        isSmall
      >
        <div className={styles.uploadFile}>
          <Button variant="secondary" onClick={openFileDialog}>
            Browse files
          </Button>
          <p>Or drop a file anywhere on top of Reader</p>
        </div>
      </ListItem>
      <ListItem
        image={<NewsletterIcon className={styles.newsletterIcon} />}
        title="Subscribe to email newsletters"
        subtitle="To skip your email inbox, directly subscribe to newsletters using your custom email address:"
        isSmall
      >
        <div className={styles.inputWithButton}>
          <input
            onClick={copyEmail}
            aria-labelledby="forward email"
            type="email"
            readOnly
            value={feedEmail}
            autoComplete="off"
          />
          <Button variant="secondary" onClick={copyEmail}>
            Copy
          </Button>
        </div>
      </ListItem>
      <ListItem
        image={<NewsletterIcon className={styles.newsletterIcon} />}
        title="Autoforward email newsletters"
        subtitle="To set up an autoforward to Feed, forward to:"
        isSmall
      >
        <div className={styles.inputWithButton}>
          <input
            onClick={copyEmail}
            aria-labelledby="forward email"
            type="email"
            readOnly
            value={feedEmail}
            autoComplete="off"
          />
          <Button variant="secondary" onClick={copyEmail}>
            Copy
          </Button>
        </div>
      </ListItem>
      <ListItem
        image={<SolidTwitterIcon className={styles.twitterLogo} />}
        title="Subscribe to public Twitter list"
        subtitle="Delivered twice daily: AM and PM."
        isSmall
      >
        <div className={styles.inputWithButton}>
          <form onSubmit={onSubmit}>
            <input
              required
              aria-labelledby="add from url"
              onChange={(e) => setUrl(e.target.value)}
              type="url"
              autoComplete="off"
              placeholder="http://"
            />
            <Button variant="secondary" type="submit">
              {buttonValue}
            </Button>
          </form>
        </div>
      </ListItem>
    </List>
  );
};

const NewsletterIcon = ({ className = '' }: { className?: string }) => {
  const isDarkMode = globalState(
    useCallback((state) => state.webEffectiveTheme === DisplayTheme.Dark, []),
  );
  if (isDarkMode) {
    return <NewsletterIconDarkMode className={className} />;
  }

  return <NewsletterIconLightMode className={className} />;
};
